import { PlantSchemaLocalized } from '../../types';
import { PlantSortSetting } from './types';
import { REVERSED_SORTS, SortKeyAccessor } from './sort-variant';

export class Sorter {
  static sort(
    plants: PlantSchemaLocalized[],
    sortSetting: Partial<PlantSortSetting>
  ): PlantSchemaLocalized[] {
    const { direction, key } = sortSetting;
    if (!key || key === 'none') {
      return plants;
    }
    const comparatorFunction = getKeyComparator(key);
    const _sorted = plants.sort(comparatorFunction);
    let reverseOrder = direction === 'desc';
    reverseOrder = REVERSED_SORTS.indexOf(sortSetting.key) !== -1 ? !reverseOrder : reverseOrder;
    return reverseOrder ? [..._sorted.reverse()] : [..._sorted];
  }
}

/**
 * Return a comparison function that will use the 'key' attribute for
 * comparison of two items.
 * @param key object attribute
 */
function getKeyComparator(key) {
  return (a, b) => {
    const varA = SortKeyAccessor[key](a);
    const varB = SortKeyAccessor[key](b);
    return varA > varB ? 1 : varA < varB ? -1 : 0;
  };
}
