import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PlantsService } from '../../modules/plants/services/plants.service';
import { PlantsActions } from './actions';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { PlantsSelectors } from './selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class PlantsEffects {
  fetchPlants$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlantsActions.fetchPlants),
      exhaustMap(() =>
        this.plantsService.getAll().pipe(
          map((result) => PlantsActions.plantsLoaded({ result })),
          catchError((err) => of(PlantsActions.plantsLoadError({ error: err })))
        )
      )
    )
  );

  fetchPlantsCache$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlantsActions.fetchPlantsCache),
      withLatestFrom(this.store.select(PlantsSelectors.plantListCacheFlag)),
      map(([action, cacheFlag]) =>
        cacheFlag ? PlantsActions.fetchPlantsCacheHit() : PlantsActions.fetchPlants()
      )
    )
  );

  fetchPlant$ = createEffect(() =>
    this.actions.pipe(
      ofType(PlantsActions.fetchPlantDetails),
      exhaustMap(({ plantId }) =>
        // TODO: can use cache here as well ?
        this.plantsService.getDetails(plantId).pipe(
          map((plant) => PlantsActions.plantDetailsLoaded({ plant })),
          catchError((err) => of(PlantsActions.plantDetailsLoadError({ error: err })))
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private plantsService: PlantsService,
    private store: Store
  ) {}
}
