<mat-toolbar>
  <div class="page-inner">
    <div class="biavl-logo">
      <a routerLink="/" (click)="navigateAction()" class="logo" alt="Biavl.dk Logo">
        <img src="assets/images/biavl-logo-blue-small.png" height="35" width="40"/>
      </a>
    </div>
    <app-toolbar-title
      (titleClick)="titleClicked()"
      (navigated)="navigateAction()"
    ></app-toolbar-title>
    <app-menu (expandChange)="setExpand($event)" [features]="appFeatures$ | async"></app-menu>
    <!-- <span *ngIf="nav$ | async as nav">{{nav.url}}</span> -->
    <div class="user">
      <ng-container *ngIf="user$ | async as user; else noUser">
        <a routerLink="/user/user" (click)="navigateAction()"><mat-icon inline>person</mat-icon>{{user?.username}}</a>
      </ng-container>
      <ng-template #noUser>
        <a routerLink="/user/login" (click)="navigateAction()">{{'USER.NOT_LOGGED_IN' | translate}}</a>
      </ng-template>
    </div>
    <div class="language">
      <app-language-select></app-language-select>
    </div>
    <div class="expand-container">
      <a class="expand-symbol" (click)="toggle()">
        <mat-icon>{{ expanded ? 'close' : 'more_vert' }}</mat-icon>
      </a>
    </div>
  </div>
</mat-toolbar>

<div class="toolbar-spacer"></div>

<div class="panel-container" [class.expanded]="expanded">
  <app-menu-panel (routeClick)="navigateAction()" [user]="user$ | async" [features]="appFeatures$ | async"></app-menu-panel>
</div>
<div class="overlay" [class.expanded]="expanded" (click)="toggle()"></div>

<!-- <app-offline-notifier></app-offline-notifier> -->
