import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval, tap } from 'rxjs';
import { AlertService } from './alert.service';

/**
 * Communicate with the service worker and do updates / reloads etc.
 *
 * The idea is to put everything useful from
 * https://angular.io/guide/service-worker-communications
 * into this one service (unless there are good reasons to use separate services).
 */
@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  constructor(
    updates: SwUpdate,
    appRef: ApplicationRef,
    private alertService: AlertService,
  ) {
    console.log("Starting app version service")
    updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.triggerAlert();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });

    // // Allow the app to stabilize first, before starting
    // // polling for updates with `interval()`.
    // const appIsStable$ = appRef.isStable.pipe(
    //   tap(isStable => console.log(`Appref isStable ${isStable}`)),
    //   first(isStable => isStable === true)
    // );
    // const recheckInterval$ = interval(60 * 1000);
    // const recheckAfterAppIsStable$ = concat(appIsStable$, recheckInterval$);

    // recheckAfterAppIsStable$.subscribe(async () => {
    //   try {
    //     console.log("checking ...");
    //     const updateFound = await updates?.checkForUpdate();
    //     if (updateFound) {
    //       this.triggerAlert();

    //     }
    //   } catch (err) {
    //     console.error('Failed to check for updates:', err);
    //   }
    // });

    updates?.unrecoverable?.subscribe(event => {
      alertService.openWarning(
        'An error occurred that we cannot recover from',
        event.reason + '\n\nPlease reload the page.',
        () => window.location.reload()
      );
    });
  }

  triggerAlert() {
    this.alertService.openWarning(
      'A new version is available',
      'Please update, it will only take a moment.',
      () => window.location.reload()
    );
  }

}
