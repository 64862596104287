import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ColorInputComponent } from './color-input/color-input.component';
import { ColorFieldComponent } from './color-field/color-field.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { PlantIconModule } from '../plant-icon/plant-icon.module';
import { ConfirmComponent } from './confirm/confirm.component';
import { TranslateModule } from '@ngx-translate/core';
import { BloomingComponent } from './blooming/blooming.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SliderComponent } from './slider/slider.component';
import { IncludesPipe } from './shared.pipes';
import { MatNativeDateModule } from '@angular/material/core';
import { MiniPlantComponent } from './mini-plant/mini-plant.component';
import { SvgbarComponent } from './svgbar/svgbar.component';
import { LocationDebugComponent } from './location-debug/location-debug.component';


@NgModule({
  declarations: [
    ColorInputComponent,
    ColorFieldComponent,
    ConfirmComponent,
    BloomingComponent,
    SliderComponent,
    MiniPlantComponent,

    IncludesPipe,
    SvgbarComponent,
    LocationDebugComponent,
  ],
  imports: [
    // For the components/declarations in here, these are required
    CommonModule,
    TranslateModule.forChild(),

    // Material (this could be moved to a separate material module)
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,

    PlantIconModule,
    NgxSliderModule,
  ],
  exports: [
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatRadioModule,

    PlantIconModule,

    ColorInputComponent,
    ColorFieldComponent,
    ConfirmComponent,
    BloomingComponent,
    SliderComponent,
    MiniPlantComponent,

    IncludesPipe,
    SvgbarComponent,
    LocationDebugComponent

  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always', appearance: 'standard' },
    },
  ],
})
export class SharedModule { }
