import { Type } from '@angular/core';
import { AppEffects } from './app/effects';
import { NavigationEffects } from './navigation/effects';
import { AuthEffects } from './auth/auth.effects';
import { PlantsEffects } from './plants/effects';
import { ReportEffects } from './report/report.effects';
import { ResultEffects } from './report/result.effects';

export const effects: Type<any>[] = [
  AppEffects,
  NavigationEffects,
  AuthEffects,
  PlantsEffects,
  ReportEffects,
  ResultEffects
];
