import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PlantReport } from '../types/plant-report';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/report/`;
  }

  addReport(report: PlantReport){
    return this.http.post(`${this.baseUrl}`, report);
  }

  getReports(){
    return this.http.get<{ results: Array<PlantReport> }>(`${this.baseUrl}`);
  }

  removeReport(id: number){
    return this.http.delete(`${this.baseUrl}` + id);
  }
}
