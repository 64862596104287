import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/modules/auth/user.service';
import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects {
  // login$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.login)
  //     // mergeMap(({user, pass}) => {
  //     //   // return of(null);
  //     // })
  //   )
  // );

  status$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.fetchStatus),
      exhaustMap(() => {
        return this.userService.fetchUser().pipe(
          map((response) => AuthActions.fetchStatusOk({ user: response })),
          catchError((error) => of(AuthActions.fetchStatusFail({ error })))
        );
      })
    )
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => [AuthActions.fetchStatus()])
    )
  );

  constructor(private actions$: Actions, private userService: UserService) {}
}
