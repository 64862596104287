import { Injectable } from '@angular/core';
import { FeatureMap } from 'src/environments/feature-map';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AuthSelectors } from 'src/app/state/auth/auth.selectors';
@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  features: FeatureMap;
  constructor(store: Store) {
    this.features = { ...environment.features };

    // Set features depending on user status and user staff status
    store.select(AuthSelectors.user).subscribe((user) => {

      // Hidden features only for staff users:
      const staffStatus = user && user.is_staff;

      this.features = (
        staffStatus && !!environment.staffFeatures
          ? { ...environment.staffFeatures }
          : { ...environment.features }
      ) as FeatureMap;
    });
  }
}
