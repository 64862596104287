import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageTitleResolver } from './resolvers/error-page-title.resolver';
import { ErrorPageDescriptionResolver } from './resolvers/error-page-description.resolver';
import { ErrorPageComponent } from './modules/core/error-page/error-page.component';
import { BaseLayoutComponent } from './modules/core/layouts/base-layout/base-layout.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'misc/welcome',
        pathMatch: 'full',
      },
      {
        path: 'plants',
        loadChildren: () => import('./modules/plants/plants.module').then((r) => r.PlantsModule),
      },
      {
        path: 'misc',
        loadChildren: () => import('./modules/misc/misc.module').then((r) => r.MiscModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then((r) => r.UserModule),
      },
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    resolve: {
      title: ErrorPageTitleResolver,
      description: ErrorPageDescriptionResolver,
    },
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: {
      title: 'ERROR_RESPONSE.404.TITLE',
      description: 'ERROR_RESPONSE.404.DESCRIPTION',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    // 'enabled' does not seem to fix the overview scroll position
    // 'top' works at least for detail page
    scrollPositionRestoration: 'top'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
