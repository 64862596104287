import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/modules/auth/user';
import { AuthActions } from './auth.actions';
import { UserProfile } from 'src/app/modules/user/userprofile';

export interface AuthState {
  status: boolean;
  user: User;
}

// Default profile - for when there is no authenticated user
const DEFAULT_FALLBACK_PROFILE = {
  showNameInToplist: null,
  acceptNotifications: null,
  nickname: '',
  betaTester: false,
  featuresPollen: true,
  featuresBeeCount: true,
  country: '',
} as UserProfile;

const initialState =
{
  status: null,
  user: null
};

export const authReducer = createReducer<AuthState>(
  initialState,
  on(AuthActions.resetAuth, (state, action) => ({
    ...state,
    status: false,
    user: null
  })),
  on(AuthActions.fetchStatusOk, (state, { user }) => ({
    ...state,
    status: true,
    user: {
      ...user,
      userprofile: user.userprofile ?? DEFAULT_FALLBACK_PROFILE
    }
  })),
  on(AuthActions.fetchStatusFail, (state) => ({
    ...state,
    status: false,
    user: null
  })),
);
