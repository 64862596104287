import { createAction, props } from '@ngrx/store';
import { NavigationStack } from './reducers';

export class NavigationActions {
  static navigation = createAction('[App] navigation', props<NavigationStack>());
  static navigationStackReset = createAction('[App] navigation reset');

  // Introduce a common place to fetch the plant id from
  static setPlantDetail = createAction('[App] plant id', props<{id}>());
}
