import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GeoRegion } from '../types/geo-area';

@Injectable({
  providedIn: 'root',
})
export class GeoAreaService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/locations/`;
  }

  getAll() {
    return this.http.get<{ results: Array<GeoRegion> }>(this.baseUrl);
  }

}
