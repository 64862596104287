export const variables = {
  availableErrorStatuses: ['404', '500', '504'],
};

export const LANGUAGES = [
  { code: 'da', translateKey: 'SHARED.LANGUAGE.DA' },
  { code: 'en', translateKey: 'SHARED.LANGUAGE.EN' },
  // { code: 'nl', translateKey: 'SHARED.LANGUAGE.NL' },
  // { code: 'de', translateKey: 'SHARED.LANGUAGE.DE' },
  // { code: 'no', translateKey: 'SHARED.LANGUAGE.NO' },
];
