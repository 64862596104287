export enum PlantType {
  Herb = 1,
  Perennial = 2,
  Bush = 3,
  Tree = 4,
  Vine = 5,
  Unknown = 999
}

export const PLANTTYPES = [
  { id: PlantType.Herb, name: 'Herb' },
  { id: PlantType.Perennial, name: 'Perennial' },
  { id: PlantType.Bush, name: 'Bush' },
  { id: PlantType.Tree, name: 'Tree' },
  { id: PlantType.Vine, name: 'Vine' },
  { id: PlantType.Unknown, name: 'Unknown' },
];

export const PLANTTYPE_NAMES = {
  [PlantType.Unknown]: 'Unknown',
  [PlantType.Herb]: 'Herb',
  [PlantType.Perennial]: 'Perennial',
  [PlantType.Bush]: 'Bush',
  [PlantType.Tree]: 'Tree',
  [PlantType.Vine]: 'Vine',
};
