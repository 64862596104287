import { PlantlistGroupSetting } from '../grouping';
import { SortVariant } from './sort-variant';

export interface PlantSortSetting {
  key: SortVariant;
  direction: 'asc' | 'desc';
}



/**
 * To simplify the settings, there are now view settings that set
 * sorting and grouping at the same time
 */
export enum ViewSetting {
  alphabetical,
  blooming
}

export class ViewSettingConfig {
  constructor(public grouping: PlantlistGroupSetting, public sorting: SortVariant) {}
}

export const viewSettingConfig = {
  [ViewSetting.alphabetical]: new ViewSettingConfig(PlantlistGroupSetting.alphabetical, SortVariant.name),
  [ViewSetting.blooming]: new ViewSettingConfig(PlantlistGroupSetting.bloomingStart, SortVariant.daysToBlooming)
};