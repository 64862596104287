import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/modules/auth/user';

export class AuthActions {
  // static loginResult = createAction('[Auth] Login Result', props<any>());
  static resetAuth = createAction('[Auth] Reset Auth');
  static fetchStatus = createAction('[Auth] Fetch Status');
  static fetchStatusOk = createAction('[Auth] Fetch Status Ok', props<{user: User }>());
  static fetchStatusFail = createAction('[Auth] Fetch Status Fail', props<{error: any }>());
}
