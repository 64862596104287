import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PlantIconComponent } from './plant-icon.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule.forChild({ extend: true })],
  declarations: [PlantIconComponent],
  exports: [PlantIconComponent],
})
export class PlantIconModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    const icons = [
      ['Bush', 'assets/icons/bush.svg'],
      ['Herb', 'assets/icons/herb.svg'],
      ['Perennial', 'assets/icons/perennial.svg'],
      ['Tree', 'assets/icons/tree.svg'],
      ['Vine', 'assets/icons/vine.svg'],
      ['Unknown', 'assets/icons/empty.svg'],

      ['Insect-Bee', 'assets/icons/plant-bee.svg'],
    ];
    icons.forEach(([name, path]) => {
      matIconRegistry.addSvgIcon(name, domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }
}
