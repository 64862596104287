import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TopPlantEntry } from '../types/top-plant-entry';

@Injectable({
  providedIn: 'root'
})

export class TopPlantsService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/topplants/`;
  }

  getItems(){
    return this.http.get<Array<TopPlantEntry>>(`${this.baseUrl}`);
  }

}
