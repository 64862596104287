import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReportSpot } from '../types/report-spot';

@Injectable({
  providedIn: 'root'
})

export class SpotService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/spots/`;
  }

  getSpots(){
    return this.http.get<{ results: Array<ReportSpot> }>(`${this.baseUrl}`);
  }

}
