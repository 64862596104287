import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppActions } from 'src/app/state/app/actions';
import { AppSelectors } from 'src/app/state/app/selectors';
import { lightFormat } from 'date-fns';
import { FeaturesService } from '../../features.service';

@UntilDestroy()
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  dateInput = new UntypedFormControl();
  locationInput = new UntypedFormControl();
  expanded = false;
  withDate = false;

  locations$;
  location;
  geopos$;
  geoposStatus$;
  user$;

  showUserInfo;

  constructor(private store: Store, private features: FeaturesService) {
    this.locations$ = this.store.select(AppSelectors.enabledLocations);
    this.geopos$ = this.store.select(AppSelectors.improvedGeopos);
    this.geoposStatus$ = this.store.select(AppSelectors.geoposStatus);
    this.withDate = features.features.dateSelectable;

    this.store
      .select(AppSelectors.userRegion)
      .pipe(
        untilDestroyed(this),
        tap((location) => {
          const newLocationId = location ? location.locationId : null;
          this.locationInput.patchValue(newLocationId, {
            emitEvent: false,
          });
        })
      )
      .subscribe();

    this.locationInput.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((selectedLoaction) => {
          this.store.dispatch(AppActions.manualSelectLocation({ locationId: selectedLoaction }));
        })
      )
      .subscribe();

    if (this.withDate) {
      // Update date from store to form
      this.store
        .select(AppSelectors.today)
        .pipe(
          untilDestroyed(this),
          tap((value) => {
            this.dateInput.patchValue(lightFormat(new Date(value), 'yyyy-MM-dd'), {
              emitEvent: false,
            });
          })
        )
        .subscribe();

      // Update date from form to store
      this.dateInput.valueChanges
        .pipe(
          untilDestroyed(this),
          tap((value) => {
            this.store.dispatch(AppActions.overrideDate({ date: value }));
          })
        )
        .subscribe();
    }
  }

  resetLocation() {
    this.store.dispatch(AppActions.useAutoLocation());
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
