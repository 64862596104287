import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavigationSelectors } from '../../../../state/navigation/selectors';
import { NavigationStack } from 'src/app/state/navigation/reducers';

@Component({
  selector: 'app-toolbar-title',
  templateUrl: './toolbar-title.component.html',
  styleUrls: ['./toolbar-title.component.scss'],
})
export class ToolbarTitleComponent {
  @Output() titleClick = new EventEmitter<null>();
  @Output() navigated = new EventEmitter<null>();
  currentNavigation$: Observable<NavigationStack>;
  previousNavigation$: Observable<NavigationStack>;
  navstate$: Observable<{
    current: NavigationStack;
    previous: NavigationStack;
  }>;

  constructor(private store: Store, private router: Router) {
    this.currentNavigation$ = store.select(NavigationSelectors.currentNavigation);
    this.previousNavigation$ = store.select(NavigationSelectors.previousNavigation);
    this.navstate$ = store.select(NavigationSelectors.currentState);
  }

  navigateToPrev(previousNavigation: NavigationStack) {
    this.router.navigate([previousNavigation.path]);
    this.navigated.emit();
  }

  menuClick() {
    this.titleClick.emit();
  }
}
