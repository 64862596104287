import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { take } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class PlantnamesService {
  prefix = environment.namesTranslate.prefix;
  suffix = environment.namesTranslate.suffix;
  constructor(private http: HttpClient) {}
  loadTranslation(locale) {
    return this.http.get<{latin, local}[]>(`${this.prefix}${locale}${this.suffix}`).pipe(take(1));
  }
}
