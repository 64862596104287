import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BloomingData } from '../types/blooming-data';

@Injectable({
  providedIn: 'root'
})

export class BloomingDataService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/livedata/`;
  }

  getItems(){
    return this.http.get<Array<BloomingData>>(`${this.baseUrl}`);
  }

}
