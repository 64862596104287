import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ReportActions } from './report.actions';
import { Store } from '@ngrx/store';
import { BloomingDataService } from '../../modules/report/services/blooming-data.service';
import { TopListService } from 'src/app/modules/report/services/toplist.service';
import { TopPlantsService } from 'src/app/modules/report/services/topplants.service';
import { ResultSelectors } from './result.selectors';
import { ResultActions } from './result.actions';

@Injectable()
export class ResultEffects {
  /**
   * Load community reports / plant sightings.
   */
  fetchBloomingDataCache$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultActions.fetchBloomingDataCache),
      withLatestFrom(this.store.select(ResultSelectors.getBloomingDataCacheFlag)),
      map(([action, cacheFlag]) =>
        cacheFlag ? ResultActions.fetchBloomingDataCacheHit() : ResultActions.fetchBloomingData()
      )
    )
  );

  fetchBloomingData$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultActions.fetchBloomingData),
      exhaustMap(() =>
        this.bloomingData.getItems().pipe(
          map((response) =>
            ResultActions.fetchBloomingDataOk({
              items: response,
            })
          ),
          catchError((err) => of(ResultActions.fetchBloomingDataFail({ error: err })))
        )
      )
    )
  );

  /**
   * Load user stats (toplist).
   */
  fetchTopListCache$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultActions.fetchTopListCache),
      withLatestFrom(this.store.select(ResultSelectors.getTopListCacheFlag)),
      map(([action, cacheFlag]) =>
        cacheFlag ? ResultActions.fetchTopListCacheHit() : ResultActions.fetchTopList()
      )
    )
  );

  // After we read from cache, we fetch again
  // TODO: testing this - does it make sense ?
  fetchTopListAfterCache$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultActions.fetchTopListCacheHit),
      map(() => ResultActions.fetchTopList())
    )
  );

  fetchTopList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultActions.fetchTopList),
      exhaustMap(() =>
        this.toplistData.getItems().pipe(
          map((response) =>
            ResultActions.fetchTopListOk({
              items: response,
            })
          ),
          catchError((err) => of(ResultActions.fetchTopListFail({ error: err })))
        )
      )
    )
  );

  /**
  * Load top plants.
  */
  fetchTopPlantsCache$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultActions.fetchTopPlantsCache),
      withLatestFrom(this.store.select(ResultSelectors.getTopListCacheFlag)),
      map(([action, cacheFlag]) =>
        cacheFlag ? ResultActions.fetchTopPlantsCacheHit() : ResultActions.fetchTopPlants()
      )
    )
  );

  // After we read from cache, we fetch again
  // TODO: testing this - does it make sense ?
  fetchTopPlantsAfterCache$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultActions.fetchTopPlantsCacheHit),
      map(() => ResultActions.fetchTopPlants())
    )
  );

  fetchTopPlants$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultActions.fetchTopPlants),
      exhaustMap(() =>
        this.topPlantsData.getItems().pipe(
          map((response) =>
            ResultActions.fetchTopPlantsOk({
              items: response,
            })
          ),
          catchError((err) => of(ResultActions.fetchTopPlantsFail({ error: err })))
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private bloomingData: BloomingDataService,
    private toplistData: TopListService,
    private topPlantsData: TopPlantsService,
    private store: Store,
  ) { }
}
