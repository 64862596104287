import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { variables } from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class ErrorPageTitleResolver implements Resolve<string> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    if (variables.availableErrorStatuses.indexOf(route.queryParams.error_status) > -1) {
      return `ERROR_RESPONSE.${route.queryParams.error_status}.TITLE`;
    }
    return 'ERROR_RESPONSE.GENERIC.TITLE';
  }
}
