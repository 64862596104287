import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PlantDetailsSchema, PlantSchema } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PlantsService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/plants/`;
  }

  getAll() {
    return this.http
      .get<{ results: Array<PlantSchema> }>(this.baseUrl);
  }

  getDetails(plantId: number) {
    return this.http
      .get<PlantDetailsSchema>(`${this.baseUrl}${plantId}`);
  }
}
