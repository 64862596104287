<footer>
  <div class="page-inner">
    <span>{{ 'FOOTER.APPNAME' | translate }} v{{ version }}</span>
    <span class="license">
      <mat-icon inline=>copyright</mat-icon> {{curYear}} - {{ 'FOOTER.COPYRIGHT' | translate }}
    </span>
    <nav>
      <a routerLink="/misc/about">{{ 'PAGES.TITLES.ABOUT' | translate }}</a>
      <a routerLink="/misc/imprint">{{ 'FOOTER.IMPRINT' | translate }}</a>
      <a routerLink="/misc/privacy">{{ 'FOOTER.PRIVACY' | translate }}</a>
    </nav>
    <!--
    <app-language-select [withLabel]="true"></app-language-select>
    -->
  </div>
</footer>
