import { GeoRegion, NestedArea } from '../modules/core/types/geo-area';
import { UserRegion } from '../types/user-location';
import { point, polygon } from '@turf/helpers';
import { default as booleanPointInPolygon } from '@turf/boolean-point-in-polygon';
import { GeoPos, GeoPosSnapShot } from '../types/geo-pos';
import { round, min, mean } from 'lodash-es';
import { isBefore, sub } from 'date-fns';

/**
 * From the list of recent positions (sorted from new to old), derive a good estimate.
 * Use the last N *recent* updates. Discard the entries that are too for off (to much worse than
 * the best accuracy), then do an average.
 * @param locationHistory
 */
export function getAdaptiveGeoposition(locationHistory: GeoPosSnapShot[]): GeoPos {
  if (!locationHistory || !locationHistory.length) {
    return null;
  }
  const CUTOFF_RANGE_FACTOR = 2;
  const CUTOFF_GOOD_ACCURACY = 15; // Always considered a good accuracy
  const LAST_N = 5;
  const LAST_SECONDS = 12;
  const timeCutoff = sub(new Date(), { seconds: LAST_SECONDS });
  const snapshots = locationHistory.slice(0, LAST_N).filter(snapshot => isBefore(timeCutoff, snapshot.ts));
  const accuracyCutoff = min(snapshots.map(snapshot => snapshot.accuracy)) * CUTOFF_RANGE_FACTOR;
  const okSnaps = snapshots.filter(snapshot => snapshot.accuracy < accuracyCutoff || snapshot.accuracy < CUTOFF_GOOD_ACCURACY);

  return {
    latitude: mean(okSnaps.map(snapshot => snapshot.latitude)),
    longitude: mean(okSnaps.map(snapshot => snapshot.longitude)),
    accuracy: mean(okSnaps.map(snapshot => snapshot.accuracy))
  }
}

/**
 * From geo position and list of locations, choose the matching and most detailled one.
 */
export function getRegionFromGeoPos(
  coords: { latitude; longitude },
  locations: Array<GeoRegion>
): UserRegion {
  if (!locations || locations.length < 1) {
    return null;
  }
  let foundByCoordinates;
  const enabledLocations = locations.filter((loc) => loc.enabled !== false);

  // Attempt to find by coordinates
  if (!!coords) {
    // Filter locations by match (contains point) status
    const matching = enabledLocations.filter((loc) => locationContainsPoint(loc, coords));
    if (matching.length > 0) {
      const withParents = matching.map((loc) => ({
        ...loc,
        parents: getParents(loc, locations),
      }));
      // Use the one with the *longest list of parents* - i.e. the most specific location
      foundByCoordinates = withParents.sort((a, b) => b.parents.length - a.parents.length)[0];
    }
  }

  // Fallback for no geolocation / not found is to use a default location,
  // given by shortcode (and lastly, use the first entry of locations list)
  const selected =
    foundByCoordinates || enabledLocations.find((loc) => loc.default) || enabledLocations[0];

  return getSelectedRegion(selected.id, locations);
}

function locationContainsPoint(location: GeoRegion, coords: { latitude; longitude }) {
  try {
    if (location.geoshape && location.geoshape.type === 'Polygon') {
      const poly = polygon(location.geoshape.coordinates);
      return booleanPointInPolygon(point([coords.longitude, coords.latitude]), poly);
    }
  } catch (err) {
    console.warn('Point in polygon failed', err);
  }

  return false;
}

/**
 * For a location id, get the full location object (with locationParents)
 *
 * @param locationId id of location
 * @param locations list of locations
 * @returns the selected location, including the parents attribute
 */
export function getSelectedRegion(locationId, locations: Array<GeoRegion>): UserRegion {
  const selected = locations.find((loc) => loc.id === locationId);
  return selected
    ? {
      locationId: selected.id,
      locationName: selected.name,
      locationParents: getParents(selected, locations),
    }
    : {};
}

/**
 * For a nested element and the list of elements, get the list of parent ids.
 */
export function getParents(location: NestedArea, locations: Array<NestedArea>) {
  let parentLoc;
  if (location.parent) {
    parentLoc = locations.find((v) => v.id === location.parent);
  }
  if (!parentLoc) {
    return [];
  }
  return [parentLoc.id].concat(getParents(parentLoc, locations));
}

// 0.0001 equals approx 11.1 m
// twice the difference, so 0.0002 should be some 45m in range
const LOCDIFF = 0.0002;

interface ShortNamedGeoObject {
  lat?: number;
  lon?: number;
}

/**
 * Filter reports by closeness to point.
 *
 * Allows to encapsulte what is regarded as close.
 * TODO: move to a circle-contains test , rather then the rectangle ?
 *
 * @param reports all the reports (geo items / generic)
 * @param geopos the geoposition
 * @returns only reports (geo items / generic)  that are "close"
 */
export function filterReportsByCloseness<T extends ShortNamedGeoObject>(
  reports: Array<T>,
  geopos: { latitude: number, longitude: number }
): Array<T> {
  return reports.filter(report => (
      Math.abs(report.lat - geopos.latitude) < LOCDIFF
      && Math.abs(report.lon - geopos.longitude) < LOCDIFF
  ));
}

/**
 * Manhatten-distance.
 *
 * Super simple. Should we use https://turfjs.org/docs/#distance instead ?
 *
 * @param p1 first point
 * @param p2 second point
 * @returns rough estimate of distance in meters
 */
export function simplePointDistance(p1: GeoPos, p2: GeoPos): number {
  const latDiff = Math.abs(p1.latitude - p2.latitude);
  const longDiff = Math.abs(p1.longitude - p2.longitude);
  return round((latDiff + longDiff) * 111111);
}