import { FeatureMap } from "./feature-map";

export const environment = {
  version: '2.5.0',
  production: true,
  apiUrl: '/api',
  translate: { prefix: '/api/texts/strings/', suffix: '/' },
  namesTranslate: { prefix: '/api/texts/names/', suffix: '/' },
  features: {
    dateSelectable: false,
    shapeMap: false,
    manualRegion: false,
    rankingScore: false,
  } as FeatureMap,
  staffFeatures: {
    dateSelectable: false,
    shapeMap: true,
    manualRegion: true,
    rankingScore: true,
  } as FeatureMap
};
