import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ReportActions } from './report.actions';
import { ReportService } from '../../modules/report/services/report.service';
import { Store } from '@ngrx/store';
import { MessageService } from '../../modules/core/message.service';
import { Router } from '@angular/router';
import { NavigationSelectors } from '../navigation/selectors';
import { SpotService } from 'src/app/modules/report/services/spot.service';

@Injectable()
export class ReportEffects {
  sendReport$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportActions.reportSubmitted),
      exhaustMap((action) =>
        this.reportService.addReport(action.report).pipe(
          map((response) =>
            ReportActions.reportSubmitOk({
              data: response,
            })
          ),
          catchError((err) => of(ReportActions.reportSubmitError({ error: err })))
        )
      )
    )
  );

  reportSubmitOKFeedback$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportActions.reportSubmitOk),
      withLatestFrom(
        this.store.select(NavigationSelectors.currentNavigation),
        this.store.select(NavigationSelectors.previousNavigation)
      ),

      // Report submitted, show message + redirect
      tap(([response, currentUrl, previousUrl]) => {
        this.message.message('REPORT.MESSAGES.REPORT_SUCCESS');

        // Where to redirect to ?
        //    For overlay report, it's the current url
        //    For report-page, it's the previous url
        //    For the latter, when page was visited directly, there needs to be a fall back url
        // TODO: if standalon report page is used as well, we need a strategy here to choose the url
        const backUrl = currentUrl.url;

        this.router.navigate([backUrl]);
      }),
      map(() => ReportActions.fetchReports())
    )
  );

  reportSubmitErrorFeedback$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ReportActions.reportSubmitError),
        tap(() => {
          this.message.message('REPORT.MESSAGES.REPORT_ERROR');
        })
      ),
    { dispatch: false }
  );

  // fetchReportsCache$ = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(ReportActions.fetchReportsCache),
  //     withLatestFrom(this.store.select(ReportSelectors.reportListCacheFlag)),
  //     map(([action, cacheFlag]) =>
  //       cacheFlag ? ReportActions.fetchReportsCacheHit() : ReportActions.fetchReports()
  //     )
  //   )
  // );

  reportList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportActions.fetchReports),
      exhaustMap(() =>
        this.reportService.getReports().pipe(
          map((response) =>
            ReportActions.getReportsSucess({
              reports: response.results,
            })
          ),
          catchError((err) => of(ReportActions.getReportsError({ error: err })))
        )
      )
    )
  );

  deleteReport$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportActions.deleteReportRequest),
      exhaustMap((action) =>
        this.reportService.removeReport(action.id).pipe(
          map((response) =>
            ReportActions.deleteReportSucess({
              result: response,
            })
          ),
          catchError((err) => of(ReportActions.deleteReportError({ error: err })))
        )
      )
    )
  );

  deleteReportOKFeedback$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportActions.deleteReportSucess),
      tap(() => {
        this.message.message('REPORT.MESSAGES.DELETE_REPORT_SUCCESS');
      }),
      map(() => ReportActions.fetchReports())
    )
  );

  deleteReportErrorFeedback$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ReportActions.deleteReportError),
        tap(() => {
          this.message.message('REPORT.MESSAGES.DELETE_REPORT_ERROR');
        })
      ),
    { dispatch: false }
  );

  fetchSpots$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportActions.fetchSpots),
      exhaustMap(() =>
        this.spots.getSpots().pipe(
          map((response) => ReportActions.fetchSpotsSuccess({ spots: response.results })),
          catchError((err) => of(ReportActions.getReportsError({ error: err })))
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private reportService: ReportService,
    private spots: SpotService,
    private message: MessageService,
    private router: Router,
    private store: Store
  ) { }
}
