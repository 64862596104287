import { PlantSchemaLocalized } from '../../types';
import { PlantGroup, PlantGroupTitle } from './types';
import { PLANTTYPE_NAMES } from '../../types/plant-type';

/**
 * Interface for a grouping strategy.
 * - extractGroup returns a PlantGroupTitle object that "describes" the group
 * - keyfunc returns the value from the plant item that is used for grouping
 * - order returns the groups in order
 */
export interface GroupStrategy {
  extractGroup(plant: PlantSchemaLocalized): PlantGroupTitle;
  keyfunc(plant: PlantSchemaLocalized): string;
  order(groupDict: { [name: string]: PlantGroup }): PlantGroup[];
}

/**
 * Baseclass - implements the default mapping from grouped object to list.
 */
export class BaseGroupingStrategy implements GroupStrategy {
  extractGroup(plant: PlantSchemaLocalized) {
    return { title: '' } as PlantGroupTitle;
  }
  keyfunc(plant: PlantSchemaLocalized): string {
    return '';
  }
  order(groupDict: { [name: string]: PlantGroup }): PlantGroup[] {
    const keys = Object.keys(groupDict);
    return keys.map((k) => groupDict[k]);
  }
}

/**
 * Group by first letter.
 */
export class AlphabetGrouping extends BaseGroupingStrategy {
  extractGroup(plant: PlantSchemaLocalized) {
    return { title: plant.name[0] } as PlantGroupTitle;
  }
  keyfunc(plant: PlantSchemaLocalized): string {
    return plant.name[0];
  }
}

/**
 * Group by plant type.
 */
export class PlantTypeGrouping extends BaseGroupingStrategy {
  extractGroup(plant: PlantSchemaLocalized) {
    return {
      plantType: plant.plantType,
      translatePrefix: 'PLANTTYPES',
      title: PLANTTYPE_NAMES[plant.plantType],
    } as PlantGroupTitle;
  }
  keyfunc(plant: PlantSchemaLocalized): string {
    return (plant.plantType || 0).toString();
  }

  order(groupDict: { [name: string]: PlantGroup }): PlantGroup[] {
    const keys = Object.keys(groupDict);
    const items = keys.map((k) => groupDict[k]);
    return items.sort((a, b) => (a.groupDef.plantType || 999) - (b.groupDef.plantType || 999));
  }
}

/**
 * Don't group.
 */
export class NullGrouping extends BaseGroupingStrategy {
  extractGroup(plant: PlantSchemaLocalized) {
    return { title: '' } as PlantGroupTitle;
  }
  keyfunc(plant: PlantSchemaLocalized): string {
    return '';
  }
}

export enum BloomingBin {
  inbloom = 'inbloom',
  soon = 'soon',
  future = 'future',
}

/**
 * Group days to bloom, using three categories/ bins for that.
 */
export class BloomStartGrouping extends BaseGroupingStrategy {
  extractGroup(plant: PlantSchemaLocalized) {
    return { title: this.keyfunc(plant), translatePrefix: 'BLOOMINGSTATE'} as PlantGroupTitle;
  }
  keyfunc(plant: PlantSchemaLocalized): string {
    if (plant.bloomingNow) {
      return BloomingBin.inbloom.valueOf();
    }
    if (plant.daysToBlooming > 0 && plant.daysToBlooming <= 31) {
      return BloomingBin.soon.valueOf();
    }
    return BloomingBin.future.valueOf();
  }
}

/**
 * Group by month blooming period starts.
 * For ordering, a "relative" (to current date) start-month is used.
 */
export class BloomingStartMonth extends BaseGroupingStrategy {
  UNDEFINED_MONTH_TITLE = '-';
  UNDEFINED_MONTH_INDEX = 99;
  extractGroup(plant: PlantSchemaLocalized) {
    return { title: plant.blmStartMth || this.UNDEFINED_MONTH_TITLE };
  }
  keyfunc(plant: PlantSchemaLocalized) {
    const relative = plant.blmStartMthRelative === undefined ?
      this.UNDEFINED_MONTH_INDEX : plant.blmStartMthRelative;
    return relative.toString();
  }

  order(groupDict: { [name: string]: PlantGroup }): PlantGroup[] {
    const keys = Object.keys(groupDict);
    return keys.map((k) => groupDict[k]);
  }

}
