import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/modules/core/services/locale.service';
import { LANGUAGES } from 'src/app/helpers';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/reducers';
import { AppSelectors } from 'src/app/state/app/selectors';
import { AppActions } from 'src/app/state/app/actions';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnDestroy {
  @Input() withLabel = false;
  selectControl = new UntypedFormControl();
  subscription;
  langs = [];
  locale$;
  constructor(private store: Store<State>) {
    this.langs = [...LANGUAGES];
    this.locale$ = this.store.select(AppSelectors.appLocale);

    // NOTE: tried to refactor to use async pipe instead of updateControl
    // Could not achieve it with using [value]="locale$|async" on mat-select FIXME
    this.subscription = this.locale$.subscribe(locale => {
      this.updateControl(locale);
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setLanguage(lcode: string) {
    this.store.dispatch(AppActions.setLocale({locale: lcode}));
  }

  updateControl(lcode: string) {
    this.selectControl.setValue(lcode, { emitEvent: false });
  }
}
