import { createReducer, on } from '@ngrx/store';

export interface GreeterState {
  messages: any[];
}

export const initialGreeterState: GreeterState = {
  messages: [
  ]
};

export const GreeterReducer = createReducer(
  initialGreeterState,
);
