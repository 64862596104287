import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { map, mergeMap, tap } from 'rxjs/operators';
import { NavigationActions } from './actions';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStack } from './reducers';

@Injectable()
export class NavigationEffects {
  onNavigation$ = createEffect(() =>
    this.actions.pipe(
      ofType(routerNavigatedAction),
      map(({ payload }) => (payload.routerState as unknown) as NavigationStack),
      mergeMap((navigationStack: NavigationStack) => {
        return this.translate.stream(navigationStack.title).pipe(
          tap((translated: string) => this.titleService.setTitle(translated)),
          map(() => {
            if (navigationStack.isRoot) {
              return NavigationActions.navigationStackReset();
            }
            return NavigationActions.navigation(navigationStack);
          })
        );
      })
    )
  );

  constructor(
    private actions: Actions,
    private titleService: Title,
    private translate: TranslateService
  ) {}
}
