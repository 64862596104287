import { PlantSchemaLocalized } from '../../types';
import { SortVariant } from '../sorting/sort-variant';

export enum PlantlistGroupSetting {
  none = 'none',
  alphabetical = 'alphabetical',
  plantType = 'plantType',
  bloom = 'bloom',
  bloomingStart = 'bloomingStart',
}

/**
 * Holds the attribute of a "group" - to be used in group header.
 */
export class PlantGroupTitle {
  title?: string;
  plantType?: number;
  daysToBloom?: number;
  pollenColor?: any;
  flowerColor?: any;
  translatePrefix?: string;
}

export class GroupDefaultSorting {
  static defaultSorts = {
    [PlantlistGroupSetting.bloom]: SortVariant.daysToBlooming,
    [PlantlistGroupSetting.bloomingStart]: SortVariant.bloomingStart,
    [PlantlistGroupSetting.alphabetical]: SortVariant.name,
  };

  static getDefault = (groupSetting: PlantlistGroupSetting) => {
    const sortSetting = GroupDefaultSorting.defaultSorts[groupSetting];
    return sortSetting ? { key: sortSetting, direction: 'asc' } : null;
  }
}

export class PlantGroup {
  constructor(title: PlantGroupTitle) {
    this.groupDef = title;
  }
  groupDef: PlantGroupTitle;
  plants: PlantSchemaLocalized[] = [];
}
