import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppActions } from '../../state/app/actions';

@Injectable({
  providedIn: 'root'
})
export class DevelopmentService {
  mocksEnabled = false;
  constructor(private store: Store) {
  }

  startMocks() {
    console.log('Development: starting mocks');
    setInterval(() => {
      if (!this.mocksEnabled) {
        return;
      }

      this.store.dispatch(AppActions.geolocationUpdated({position: {
        coords: {
          latitude: 10 + Math.random(),
          longitude: 40 + Math.random()
        }
      }}));
    }, 2500);
  }
}
