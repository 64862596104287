import { createSelector } from '@ngrx/store';
import { State } from '../reducers';
import { AuthState } from './auth.reducers';

export const authStateSelector = (state: State) => state.auth;

export class AuthSelectors {
  static status = createSelector(authStateSelector, (state: AuthState) => state.status);

  /** Get positive logged in status or raise error
   *
   * can be used with retry to wait until logged in status arrives
   */
  static loggedInOrError = createSelector(authStateSelector, (state: AuthState) => {
    if (state.status !== null) {
      return state.status;
    } else {
      throw Error("Login status not fetched yet");
    }
  });

  static userName = createSelector(authStateSelector, (state: AuthState) =>
    state.user ? state.user.username : '-'
  );
  static user = createSelector(authStateSelector, (state: AuthState) => state.user);
  static authStatus = createSelector(AuthSelectors.status, AuthSelectors.user, (status, user) => ({
    status,
    user,
  }));
}
