import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  title$: Observable<string>;
  description$: Observable<string>;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.title$ = activatedRoute.data.pipe(map(d => d.title));
    this.description$ = activatedRoute.data.pipe(map(d => d.description));
  }

}
