import { PlantSchemaLocalized } from '../../types';
import { PlantlistGroupSetting, PlantGroup } from './types';
import {
  NullGrouping,
  AlphabetGrouping,
  PlantTypeGrouping,
  GroupStrategy,
  BloomStartGrouping,
  BloomingStartMonth,
} from './group-strategy';

/**
 * Mapping from grouping strategy enum to actual strategy implementation.
 */
const SETTING_MAP = new Map<PlantlistGroupSetting, GroupStrategy>([
  [PlantlistGroupSetting.none, new NullGrouping()],
  [PlantlistGroupSetting.alphabetical, new AlphabetGrouping()],
  [PlantlistGroupSetting.plantType, new PlantTypeGrouping()],
  [PlantlistGroupSetting.bloom, new BloomStartGrouping()],
  [PlantlistGroupSetting.bloomingStart, new BloomingStartMonth()],
]);

/**
 * Contans one static member method group that performs grouping
 * (defined by the stratset parameter) on an array of plants.
 * It handles the initialisation of the grouping strategy and performs grouping
 * and finally ordering of groups.
 */
export class PlantListGrouper {
  static group = (
    plants: PlantSchemaLocalized[],
    stratset: PlantlistGroupSetting
  ): PlantGroup[] => {
    const groupStrategy: GroupStrategy = SETTING_MAP.get(stratset);
    const groupedDict = plants.reduce((prev, cur) => {
      const key = groupStrategy.keyfunc(cur);
      if (!prev[key]) {
        prev[key] = new PlantGroup(groupStrategy.extractGroup(cur));
      }
      prev[key].plants.push(cur);
      return prev;
    }, {} as { [name: string]: PlantGroup });

    return groupStrategy.order(groupedDict);
  }
}
