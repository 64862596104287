export function hexToRgb(color: string): [number, number, number] {
  const normalized = color[0] === '#' ? color.substr(1, 6) : color.substr(0, 6);
  const red = parseInt(normalized.substr(0, 2), 16);
  const green = parseInt(normalized.substr(2, 2), 16);
  const blue = parseInt(normalized.substr(4, 2), 16);
  return [red, green, blue];
}

export function colorDifferenceDistance(color1: string, color2: string): number {
  const color1RGB = hexToRgb(color1);
  const color2RGB = hexToRgb(color2);
  return Math.sqrt(
    Math.pow(color1RGB[0] - color2RGB[0], 2) +
      Math.pow(color1RGB[1] - color2RGB[1], 2) +
      Math.pow(color1RGB[2] - color2RGB[2], 2)
  );
}

/**
 * https://en.wikipedia.org/wiki/Color_difference#Uniform_color_spaces
 * @param color1
 * @param color2
 * @returns Square of the Difference
 */
export function betterColorDistance(color1: string, color2: string): number {
  const color1RGB = hexToRgb(color1);
  const color2RGB = hexToRgb(color2);

  const Rsq = Math.pow(color1RGB[0] - color2RGB[0], 2);
  const Gsq = Math.pow(color1RGB[1] - color2RGB[1], 2);
  const Bsq = Math.pow(color1RGB[2] - color2RGB[2], 2);
  const Rmean = color1RGB[0] + color2RGB[0] / 2;

  if (Rmean < 128) {
    return  (2 * Rsq + 4 * Gsq + 3 * Bsq);
  } else {
    return  (3 * Rsq + 4 * Gsq + 2 * Bsq);
  }
}

export function lightness(color: string) {
  const [r, g, b] = hexToRgb(color);
  return ((0.2989 * r + 0.587 * g + 0.114 * b) / 255);
}
