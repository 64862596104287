import { LoadingState } from '../types/network-entity';
import { HttpErrorResponse } from '@angular/common/http';

export function createQuietNetworkState(): LoadingState {
  return {
    loading: false,
    hasError: false,
  };
}

export function createLoadingNetworkState(): LoadingState {
  return {
    loading: true,
    hasError: false,
  };
}

export function createErrorNetworkState(error: HttpErrorResponse): LoadingState {
  return {
    loading: false,
    hasError: true,
    errorMessage: error.message,
    error,
  };
}

export function combineNetworkState(a: LoadingState, b: LoadingState) {
  return {
    loading: a?.loading || b?.loading,
    hasError: a?.hasError || b?.hasError,
    errorMessage: a?.errorMessage || b?.errorMessage,
    error: a?.error || b?.error
  }
}

// export function createInitialNetworkEntity<T = unknown>(initialData?: T): NetworkEntity<T> {
//   return {
//     data: initialData,
//     loading: true,
//     hasError: false,
//   };
// }

// export function createErrorNetworkEntity<T = unknown>(error: HttpErrorResponse): NetworkEntity<T> {
//   console.error(error);
//   return {
//     data: undefined,
//     loading: false,
//     hasError: true,
//     errorMessage: error.message,
//     error,
//   };
// }

// export function resolveNetworkEntity<T = unknown>(data: T): NetworkEntity<T> {
//   return {
//     data,
//     loading: false,
//     hasError: false,
//   };
// }

// export function resolveNetworkListEntity<I = unknown>(
//   entities: Array<I>,
//   totalLength?: number
// ): NetworkListEntity<I> {
//   return {
//     entities,
//     total: totalLength ? totalLength : entities.length,
//   };
// }

// export function createInitialNetworkListEntity<T = unknown>(): NetworkListEntity<T> {
//   return {
//     entities: [],
//     total: undefined,
//   };
// }

// export function onError<T = unknown>(fn: (data: NetworkEntity<T>) => void, statusCode?: number) {
//   return (source: Observable<NetworkEntity<T>>): Observable<NetworkEntity<T>> => {
//     return source.pipe(
//       tap((networkEntity) => {
//         if (networkEntity.hasError) {
//           if (statusCode !== undefined && networkEntity.error.status === statusCode) {
//             fn(networkEntity);
//           }
//           if (statusCode === undefined) {
//             fn(networkEntity);
//           }
//         }
//       })
//     );
//   };
// }

// export function onSuccess<T = unknown>(fn: (data: NetworkEntity<T>) => void) {
//   return (source: Observable<NetworkEntity<T>>): Observable<NetworkEntity<T>> => {
//     return source.pipe(
//       tap((networkEntity) => {
//         if (!networkEntity.hasError && !networkEntity.loading) {
//           fn(networkEntity);
//         }
//       })
//     );
//   };
// }

// export function success<T = unknown>(fn?: (data: NetworkEntity<T>) => void) {
//   return (source: Observable<NetworkEntity<T>>): Observable<NetworkEntity<T>> => {
//     return source.pipe(
//       filter((networkEntity) => !networkEntity.hasError && !networkEntity.loading),
//       first(),
//       tap(fn)
//     );
//   };
// }
