import { createSelector } from '@ngrx/store';
import { State } from '../reducers';
import { NavigationState } from './reducers';

export const navigationStateSelector = (state: State) => state.navigation;

export class NavigationSelectors {
  static navigationStack = createSelector(
    navigationStateSelector,
    (state: NavigationState) => state.navigationStack
  );

  static previousNavigation = createSelector(
    navigationStateSelector,
    (state: NavigationState) => state.navigationStack[state.navigationStack.length - 2]
  );

  static currentNavigation = createSelector(
    navigationStateSelector,
    (state: NavigationState) => state.navigationStack[state.navigationStack.length - 1]
  );

  static currentState = createSelector(
    NavigationSelectors.previousNavigation,
    NavigationSelectors.currentNavigation,
    (prev, cur) => ({
      current: cur,
      previous: prev
    })
  );

  static getPlantId = createSelector(
    navigationStateSelector,
    (state: NavigationState) => state.plantId
  );
}
