import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppFeatures } from '../../types/app-features';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  @Input() features: AppFeatures;
  @Output() expandChange = new EventEmitter<boolean>();


  constructor() {}

  hide() {
    this.expandChange.emit(false);
  }
}
