import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSnapshot } from '@angular/router';
import { NavigationStack } from './state/navigation/reducers';

export class CustomRouterStateSerializer implements RouterStateSerializer<NavigationStack> {
  serialize(routerState: RouterStateSnapshot): NavigationStack {
    let r = routerState.root;
    // const p = [r];
    while (r.firstChild) {
      r = r.firstChild;
      // p.push(r);
      // console.log(r.data);
    }

    const serializedState = {
      url: routerState.url,
      title: r.data.title,
      isRoot: !!r.data.isRoot,
      isBase: !!r.data.isBase,
      path: routerState.url,
    };


    return serializedState;
  }
}
