import { Component, Inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { AuthSelectors } from 'src/app/state/auth/auth.selectors';
import { FeaturesService } from '../../features.service';
import { AppSelectors } from 'src/app/state/app/selectors';
import { Observable } from 'rxjs';
import { AppFeatures } from '../../types/app-features';
// import { NavigationSelectors } from 'src/app/state/navigation/selectors';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  expanded = false;
  user$;
  // nav$;
  // region$;
  appFeatures$: Observable<AppFeatures>;


  constructor(@Inject(WINDOW) private window, features: FeaturesService, private store: Store) {
    this.user$ = this.store.select(AuthSelectors.user);
    this.appFeatures$ = this.store.select(AppSelectors.appFeatures);
    // this.nav$ = this.store.select(NavigationSelectors.currentNavigation);
    // this.region$ = this.store.select(AppSelectors.userRegion);
  }

  titleClicked() {
    this.toggle();
  }

  navigateAction() {
    this.hide();
  }

  toggle() {
    this.setExpand(!this.expanded);
  }

  hide() {
    this.setExpand(false);
  }

  setExpand(expanded) {
    this.expanded = expanded;
    this.window.document.body.style.overflow = expanded ? 'hidden' : 'auto';
  }
}
