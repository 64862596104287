import { colorDifferenceDistance } from 'src/app/helpers/colors';
import { PlantSchemaLocalized } from '../types/plants';
import { PlantsSearchFilter } from '../types/plants-filter';

export class PlantSearchFilter {
  static hasQuery(filters: PlantsSearchFilter) {
    return (filters.query || '').trim().length > 0;
  }

  static filter = (plants: PlantSchemaLocalized[], filters: PlantsSearchFilter) => {
    const filterFns: ((entity: PlantSchemaLocalized) => boolean)[] = [];
    if (PlantSearchFilter.hasQuery(filters)) {
      const q = (filters.query || '').toLowerCase().trim();

      plants = plants.map((entity) => ({
        ...entity,
        // give 2 for starts with , 1 for contained, 0 for nothing
        searchRank:
          entity.name.toLowerCase().indexOf(q) === 0
            ? 2
            : [entity.name, entity.species].join(' ').toLowerCase().indexOf(q) !== -1
            ? 1
            : 0,
      }));

      filterFns.push((entity) => {
        return entity.searchRank > 0;
      });
    }
    if (filters.color) {
      filterFns.push((entity) => {
        return entity.colorsPollen.some(
          (c) => colorDifferenceDistance(c.colorval, filters.color) < 100
        );
      });
    }
    if (!!filters.plantType && filters.plantType.length > 0) {
      filterFns.push((entity) => filters.plantType.indexOf(entity.plantType) > -1);
    }

    return plants.filter((entity) => filterFns.every((filterFn) => filterFn(entity)));
  }
}
