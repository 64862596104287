import { getDayOfYear } from 'date-fns';
import { PlantSchemaLocalized } from '../../types';

export enum SortVariant {
  none = 'none',
  name = 'name',
  priority = 'priority',
  pollen = 'pollen',
  nectar = 'nectar',
  daysToBlooming = 'daysToBlooming',
  bloomingStart = 'bloomingStart',

  // internal only
  searchRank = 'searchRank',
}


export const REVERSED_SORTS = [
  SortVariant.pollen, SortVariant.nectar
];

export const SortKeyAccessor = {
  [SortVariant.name]: (mp: PlantSchemaLocalized) => mp.name,
  [SortVariant.priority]: (mp: PlantSchemaLocalized) => mp.priority,
  [SortVariant.pollen]: (mp: PlantSchemaLocalized) => (mp.pollen || 0) + (mp.pollenExtra || 0),
  [SortVariant.nectar]: (mp: PlantSchemaLocalized) => mp.nectar,
  [SortVariant.daysToBlooming]: (mp: PlantSchemaLocalized) => mp.daysToBlooming,
  [SortVariant.bloomingStart]: (mp: PlantSchemaLocalized) =>
    mp.bloomingPeriod ? getDayOfYear(new Date(mp.bloomingPeriod.bloomingStartDate)) : undefined,
  [SortVariant.searchRank]: (mp: PlantSchemaLocalized) => mp.searchRank,
};

