import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { ColorService } from 'src/app/modules/core/services/color.service';
import { AppActions } from './actions';
import { State } from '../reducers';
import { AppSelectors } from './selectors';
import { of } from 'rxjs';
import { GeoAreaService } from 'src/app/modules/core/services/geo-area.service';
import { GeolocationService, GEOLOCATION_SUPPORT } from '@ng-web-apis/geolocation';
import { PlantsActions } from '../plants/actions';
import { BrowserGeoCoordinates } from 'src/app/types/geo-pos';
import { ResultActions } from '../report/result.actions';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/modules/core/services/locale.service';
import { PlantnamesService } from 'src/app/modules/core/services/plantnames.service';

@Injectable()
export class AppEffects {
  fetchColors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchColors),
      exhaustMap(() =>
        this.colors.getAll().pipe(
          map((result) => AppActions.colorsLoaded({ result })),
          catchError((err) => of(AppActions.colorsLoadError({ error: err })))
        )
      )
    )
  );

  fetchColorsCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchColorsCache),
      withLatestFrom(this.store.select(AppSelectors.colors)),
      map(([action, colors]) =>
        colors && colors.length > 0 ? AppActions.fetchColorsCacheHit() : AppActions.fetchColors()
      )
    )
  );

  fetchGeoLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.requestGeoPos),

      // TODO: remove when this is set up correctly
      tap(() => console.log('Requesting location: INIT')),

      withLatestFrom(this.store.select(AppSelectors.geoPosDebugInfo)),
      filter(([action, updateStatus]) => this.geolocationSupport && !(updateStatus.updates > 0)),

      // TODO: remove when this is set up correctly
      tap(() => console.log('Requesting location: SETUP')),

      exhaustMap(() =>
        this.geolocationService$.pipe(

          // Getting a lot of updates when developing in Firefox.
          // TODO find out what is happending
          // Adding throttling, which cannoot really hurt here for a reasonable interval
          throttleTime(2000),

          // TODO: remove when this is set up correctly
          // tap((val) => console.log('Location update: UPDATE', val)),

          map((position: BrowserGeoCoordinates) => AppActions.geolocationUpdated({ position })),
          catchError((err) => of(AppActions.geolocationUpdateFail({ error: err })))
        )
      )
    )
  );

  fetchLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchLocations),
      exhaustMap(() =>
        this.locations.getAll().pipe(
          map((result) => AppActions.locationsLoaded({ result })),
          catchError((err) => of(AppActions.locationsLoadError({ error: err })))
        )
      )
    )
  );

  fetchLocationsCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchLocationsCache),
      withLatestFrom(this.store.select(AppSelectors.locations)),
      map(([action, locations]) =>
        locations && locations.length > 0
          ? AppActions.fetchLocationsCacheHit()
          : AppActions.fetchLocations()
      )
    )
  );

  setLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.setLocale),
      map(({locale}) => {
        this.translate.use(locale);
        this.localeService.updateLanguage(locale);
      })), {dispatch: false}
  );

  fetchNamesTranslation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.setLocale),
      exhaustMap(({locale}) => {
        return this.plantnames.loadTranslation(locale).pipe(
          map(data => AppActions.plantNamesLoaded({locale, data})))
      }))
    );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => [
        AppActions.fetchColorsCache(),
        AppActions.fetchLocationsCache(),
        AppActions.requestGeoPos(),
        PlantsActions.fetchPlantsCache(),

        // Load reports for greeter widget
        // TODO: define custom endpoint for this so as to load less data upfront?
        ResultActions.fetchBloomingDataCache(),
      ])
    )
  );

  constructor(
    private actions$: Actions,
    private colors: ColorService,
    private locations: GeoAreaService,
    private store: Store<State>,
    private geolocationService$: GeolocationService,

    // TODO: can TranslateService dependency be moved into LocaleService ?
    // and / or put all translate related in to separate effects
    private translate: TranslateService,
    private localeService: LocaleService,
    private plantnames: PlantnamesService,

    @Inject(GEOLOCATION_SUPPORT) private readonly geolocationSupport: boolean
  ) {}
}
