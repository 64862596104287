<div class="panel">
  <app-language-select></app-language-select>

  <mat-form-field *ngIf="locations$ | async as locations">
    <mat-label translate="SHARED.LOCATION"></mat-label>
    <mat-select [formControl]="locationInput">
      <mat-option *ngFor="let location of locations" [value]="location.id">{{
        location.name
      }}</mat-option>
    </mat-select>
    <button mat-icon-button matSuffix (click)="resetLocation()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint>
      <span *ngIf="geopos$ | async as geopos" data-test="settings-geoposition">{{
        geopos.latitude.toFixed(4) + ',' + geopos.longitude.toFixed(4)
      }}</span>
      <span *ngIf="geoposStatus$ | async as geoposStatus" [title]="geoposStatus">Status: {{ geoposStatus | slice : 0 : 65 }}...</span>
    </mat-hint>
  </mat-form-field>

  <mat-form-field *ngIf="withDate">
    <mat-label translate="SHARED.CURRENT_DATE"></mat-label>
    <input matInput type="date" name="today" [formControl]="dateInput" />
  </mat-form-field>
</div>
