import { createAction, props } from '@ngrx/store';
import { TopListEntry } from 'src/app/modules/report/types/top-list-entry';
import { TopPlantEntry } from 'src/app/modules/report/types/top-plant-entry';
import { DateRange } from 'src/app/types/date-range';
import { BloomingData } from '../../modules/report/types/blooming-data';
import { PlantReport, ReportedPlant } from '../../modules/report/types/plant-report';

export class ResultActions {

  // fetch blooming data reports
  static fetchBloomingDataCache = createAction('[bloomingdata] check cache');
  static fetchBloomingDataCacheHit = createAction('[bloomingdata] fetch from cache');
  static fetchBloomingData = createAction('[bloomingdata] fetch data');
  static fetchBloomingDataOk = createAction(
    '[bloomingdata] fetch data ok',
    props<{ items: BloomingData[] }>()
  );
  static fetchBloomingDataFail = createAction(
    '[bloomingdata] fetch data fail',
    props<{ error: any }>()
  );
  static filterBloomingDataByPlant = createAction(
    '[bloomingdata] filter blooming data',
    props<{ plant: ReportedPlant, showAll: boolean }>()
  );
  static clearPlantSelection = createAction('[bloomingdata] filter blooming data clear');
  static setBaseFilter = createAction(
    '[bloomingdata] set vis filter',
    props<{ state: {locationId: number, year: number }}>()
  );

  static setFilterDateRange = createAction(
    '[bloomingdata] filter blooming data from',
    props<{ dateRange: DateRange }>()
  );

  // fetch user toplist
  static fetchTopListCache = createAction('[toplist] check cache');
  static fetchTopListCacheHit = createAction('[toplist] fetch from cache');
  static fetchTopList = createAction('[toplist] fetch data');
  static fetchTopListOk = createAction(
    '[toplist] fetch data ok',
    props<{ items: TopListEntry[] }>()
  );
  static fetchTopListFail = createAction('[toplist] fetch data fail', props<{ error: any }>());

  // fetch top reported plants
  static fetchTopPlantsCache = createAction('[topplants] check cache');
  static fetchTopPlantsCacheHit = createAction('[topplants] fetch from cache');
  static fetchTopPlants = createAction('[topplants] fetch data');
  static fetchTopPlantsOk = createAction(
    '[topplants] fetch data ok',
    props<{ items: TopPlantEntry[] }>()
  );
  static fetchTopPlantsFail = createAction('[topplants] fetch data fail', props<{ error: any }>());
  static resetMapPlantFilter = createAction('[bloomingdata filter] reset plant filter');
}
