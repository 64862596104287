<div class="container">
  <mat-icon [inline]="true">language</mat-icon>
  <label *ngIf="withLabel">
    {{ 'SHARED.LANGUAGE.LANGUAGE' | translate }}
  </label>
  <mat-select [formControl]="selectControl" [class.withlabel]="withLabel">
    <mat-option *ngFor="let code of langs" [value]="code.code" (click)="setLanguage(code.code)">
      {{ code.translateKey | translate }}
    </mat-option>
  </mat-select>
</div>
