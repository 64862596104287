import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { Observable, catchError, of } from 'rxjs';

/**
 * Fallback fetch to english language file.
 *
 * Overriding the default loader - NOTE: must keep the interface aligned with
 * https://github.com/ngx-translate/core/blob/master/packages/http-loader/lib/http-loader.ts
 *
 */
class TranslateFallbackHttpLoader extends TranslateHttpLoader {
  public getTranslation(lang: string): Observable<Object> {
    return super.getTranslation(lang).pipe(
      catchError((err) => {
        console.warn(`Error in loading ${lang}`, err.statusText);
        if (lang !== 'en') {
          console.log('Fallback to en');
          return super.getTranslation('en');
        } else {
          console.warn('No translation could be loaded');
          return of({});
        }
      })
    )
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateFallbackHttpLoader(http, environment.translate.prefix, environment.translate.suffix);
}
