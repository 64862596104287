export enum SiteModes {
    betterb="betterb",
    pollen="pollen"
}

type ModesMap = {[key in keyof typeof SiteModes]?: boolean};

export interface AppFeatures {
    modes: ModesMap;
    largeFab: boolean;
}

export const appFeaturesDefault = {
    modes: [],
    largeFab: true
} as AppFeatures;