import { createSelector } from '@ngrx/store';
import { isAfter, isBefore, format } from 'date-fns';
import { AppSelectors } from 'src/app/state/app/selectors';
import { NavigationSelectors } from '../navigation/selectors';
import { PlantsSelectors } from '../plants/selectors';
import { State } from '../reducers';
import { groupBy, orderBy} from 'lodash-es';

export const reportStateSelector = (state: State) => state.report;

const TOPPLANTS_COUNT = 4;

export class ResultSelectors {

  // Pull in plants form the catalogue, allows lookups of the plant by id in the reports.
  static plantLookUp = createSelector(PlantsSelectors.extendedPlants, (plants) =>
    plants.reduce((prev, cur) => ({ ...prev, [cur.id]: cur }), {})
  );

  // Live view (data visualisation)
  // Unfiltered (all locations)
  static _getBloomingData = createSelector(
    reportStateSelector,
    ResultSelectors.plantLookUp,
    // Combine the report data with the catalogue of plants (full plant data in reportedPlant)
    (state, lookup) =>
      state.bloomingData.map((report) => ({
        ...report,
        reportedPlant: lookup[report.reportedPlant.id] || report.reportedPlant,
      }))
  );

  static getBloomingDataCacheFlag = createSelector(
    reportStateSelector,
    (state) => state.bloomingDataCacheFlag
  );

  static getBloomingDataLoading = createSelector(
    reportStateSelector,
    (state) => state.bloomingDataLoading
  );

  // Filtering state for the report view
  static _getBloomingDataFilter = createSelector(
    reportStateSelector,
    (state) => state.bloomingDataFilter
  );

  // Filtering state for the report view
  // It takes the default location, if a location in the filter is not set
  static getBloomingDataFilter = createSelector(
    ResultSelectors._getBloomingDataFilter,
    AppSelectors.defaultLocation,
    (bloomingDataFilter, defaultLocation) => ({
      ...bloomingDataFilter,
      locationId: bloomingDataFilter.locationId || defaultLocation?.id,
    })
  );

  /**
   * Blooming data filtered to match the visulization location (and selected year, if any)
   * This is the basis for the reporting-result views.
   */
  static getBloomingDataForVisLoc = createSelector(
    ResultSelectors._getBloomingData,
    ResultSelectors.getBloomingDataFilter,
    (bloomingData, bloomingFilter) => {
      const byLocation = bloomingData.filter(
        (report) => (report.locations_list || []).indexOf(bloomingFilter.locationId) !== -1
      );
      if (!bloomingFilter.year) {
        return byLocation;
      }
      return byLocation.filter(
        (report) =>
          report.reportDate && new Date(report.reportDate).getFullYear() === bloomingFilter.year
      );
    }
  );

  // Blooming data filtered to match the default location
  static getBloomingDataForDefaultLoc = createSelector(
    ResultSelectors._getBloomingData,
    AppSelectors.defaultLocation,
    (bloomingData, defaultLoc) =>
      bloomingData.filter((report) => (report.locations_list || []).indexOf(defaultLoc?.id) !== -1)
  );

  /**
   * The report data for the main map widget.
   * Filtered by date and plant, via the data/filter state.
   */
  static getFilteredBloomingData = createSelector(
    ResultSelectors.getBloomingDataForVisLoc,
    ResultSelectors.getBloomingDataFilter,
    (bloomingData, filter) => {
      let filteredData = bloomingData;

      // Filter by plants
      filteredData = filter.plants.length > 0 ? filteredData.filter((item) => filter.plants.includes(item.reportedPlant.id)): filteredData;

      if (filter.dateRange?.fromDate || filter.dateRange?.toDate) {
        filteredData = filteredData.filter(
          (item) =>
            isAfter(new Date(item.reportDate), filter.dateRange.fromDate) &&
            isBefore(new Date(item.reportDate), filter.dateRange.toDate)
        );
      }
      return {
        filter,
        filteredData,
      };
    }
  );

  // Group by plant
  static reportsFilteredGroupedByPlant = createSelector(
    ResultSelectors.getBloomingDataForVisLoc, (data) => {
      const groupDict = groupBy(data, 'reportedPlant.id');
      const mapped = Object.keys(groupDict).map((key) => {
        const items = groupDict[key];
        const dates = items.map((obsv) => obsv.reportDate).sort();
        return {
          items,
          plant: items[0].reportedPlant,
          from: dates[0],
          to: dates[dates.length - 1],
        };
      });
      return orderBy(mapped, (group) => group.plant.name);
    }
  );

    // Max report per week - used as releative-maximum value for the bar charts of top plants per week
    // Just an estimate of what is a good value, since we are not grouping by plant id and
    // rather taking the full count of the time frame (1 week )
    static maxByWeek = createSelector(
      ResultSelectors.getBloomingDataForVisLoc, (data) => {
        const reportDates = data.map(report => {
          const ts = Date.parse(report.reportDate);
          return isNaN(ts) ? null : new Date(ts);
        }).filter(date => !!date);
        const groupDict = groupBy(reportDates, (dt) => {
          console.log(typeof(dt));
          return format(dt, 'yyyy-w');
        });
        const counts = Object.keys(groupDict).map((key) => groupDict[key].length);
        return Math.max(...counts);
      }
    );

  /**
   * Get blooming data filtered by the current plant id.
   * NOTE: This is used in the blooming period widget (with live data) on plant detail.
   *
   * TODO: would not require fully extended data (plant details), maybe possible
   *    to base on blooming data "earlier" up in the data pipeline (e.g.
   *    directly from backend)
   */
  static getBloomingDataForCurrentPlant = createSelector(
    NavigationSelectors.getPlantId,
    ResultSelectors.getBloomingDataForDefaultLoc,
    (id, data) => data.filter((rep) => rep.plant === id)
  );

  // User Stats
  static getTopListCacheFlag = createSelector(
    reportStateSelector,
    (state) => state.toplistDataCacheFlag
  );
  static getTopListLoading = createSelector(
    reportStateSelector,
    (state) => state.toplistDataLoading
  );
  // Top List data + Variants
  static getTopList = createSelector(reportStateSelector, (state) => state.toplistData || []);
  static getTopListAll = createSelector(ResultSelectors.getTopList, (toplist) =>
    toplist.filter((tl) => tl.range === 'all')
  );
  static getTopListYTD = createSelector(ResultSelectors.getTopList, (toplist) =>
    toplist.filter((tl) => tl.range === 'ytd')
  );

}
