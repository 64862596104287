import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { ApplicationReducer, AppState } from './app/reducers';
import { NavigationReducer, NavigationStack, NavigationState } from './navigation/reducers';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { AuthState , authReducer } from './auth/auth.reducers';
import { GreeterReducer, GreeterState } from './greeter/greeter.reducers';
import { PlantsReducer, PlantsState } from './plants/reducers';
import { ReportReducer, ReportState } from './report/report.reducers';

export interface State {
  app: AppState;
  navigation: NavigationState;
  router: RouterReducerState<NavigationStack>;
  auth: AuthState;
  greeter: GreeterState;
  plants: PlantsState;
  report: ReportState;
}

export const reducers: ActionReducerMap<State> = {
  app: ApplicationReducer,
  navigation: NavigationReducer,
  router: routerReducer,
  auth: authReducer,
  greeter: GreeterReducer,
  plants: PlantsReducer,
  report: ReportReducer,

};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
