import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Registration } from './registration';
import { User } from './user';

export class AuthTokenResponse {
  key: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/rest-auth`;
  }

  auth(username: string, password: string): Observable<AuthTokenResponse> {
    const req = this.http.post<AuthTokenResponse>(this.baseUrl + '/login/', {
      username,
      password,
    });
    return req;
  }

  logout(): Observable<AuthTokenResponse> {
    const req = this.http.post<AuthTokenResponse>(this.baseUrl + '/logout/', {});
    return req;
  }

  /**
   * Fetch current (session) user
   */
  fetchUser() {
    return this.http.get<User>(this.baseUrl + '/user/');
  }

  setPassword(oldPassword: string, newPassword1: string, newPassword2: string) {
    return this.http.post(this.baseUrl + '/password/change/', {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2,
    });
  }

  /**
   * Set personal data of user (and username).
   */
  setPersonalData(first_name: string, last_name: string) {
    return this.http.patch(this.baseUrl + '/user/', {
      first_name: first_name,
      last_name: last_name,
    });
  }

  resetPassword(email: string) {
    return this.http.post(this.baseUrl + '/password/reset/', {
      email,
    });
  }

  resetPasswordConfirm(uid: string, token: string, newPassword1: string, newPassword2: string) {
    return this.http.post(this.baseUrl + '/password/reset/confirm/', {
      uid,
      token,
      new_password1: newPassword1,
      new_password2: newPassword2,
    });
  }

  signup(data: Registration) {
    const regData = {
      ...data,

      // Tie username to email
      username: data.email,

      // Convert checkboxes to booleans
      acceptNotifications: !!data.acceptNotifications,
      acceptTerms: !!data.acceptTerms,
      showNameInToplist: !!data.showNameInToplist,
    };
    console.log(regData);
    return this.http.post(this.baseUrl + '/registration/', regData);
  }
}
