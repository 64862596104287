import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TopListEntry } from '../types/top-list-entry';

@Injectable({
  providedIn: 'root'
})

export class TopListService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/toplist/`;
  }

  getItems(){
    return this.http.get<Array<TopListEntry>>(`${this.baseUrl}`);
  }

}
