import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PlantColor } from '../types/plant-color';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/colors/`;
  }

  getAll() {
    return this.http.get<{ results: Array<PlantColor> }>(this.baseUrl);
  }

}
